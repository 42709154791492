<template>
	<div style="position: relative; width: 100%;">
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">Ace Stations</p>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in demoGroupsShortcut" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemographic">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenDaypart">
						<option v-for="opt in dayparts" :key="opt.id" :value="opt.id">{{opt.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div class="chart-rows" style="padding-top: 50px;">
			<div style="display: flex; margin-bottom: 20px;">
				<div>
					<select class="select-styled" v-model="chosenStartYear">
						<option v-for="yr in yearOptions" :value="yr" :key="yr">{{yr}}</option>
					</select>
				</div>
				<div style="display: flex; align-items: center; margin: 0 10px;"><span> to </span></div>
				<div>
					<select class="select-styled" v-model="chosenEndYear">
						<option v-for="yr in yearOptions" :value="yr" :key="yr">{{yr}}</option>
					</select>
				</div>
			</div>

			<ChartHolderDash charttitle="" source="" :hidesource="true" :height="500" :showzoomoption="false" :hideheadsection="true">
				<template v-slot:default>
					<ApexFreqLineTrendMultiAudology v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="chartDataFiltered" :chosendaypart="chosenDaypart" :chosendemo="chosenDemographic" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationObs" :surveylist="surveyListIdOldToNew"
					></ApexFreqLineTrendMultiAudology>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</template>
			</ChartHolderDash>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexFreqLineTrendMultiAudology from "@/components/ApexFreqLineTrendMulti.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default {
	name: 'AceOnlyStations',
	components: {
		HeaderNav,
		ApexFreqLineTrendMultiAudology,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			getChartsBtnWorking: false,
			chartsSource: [],
			chartsTitle: [],
			cadence: 'survey', //survey, year
			startYear: 2021, //first available year
			chosenStartYear: 2022,
			chosenEndYear: 2025,

			chosenMarket: 111,
			chosenDemographic: 48,
			chosenDemoGroup: 'People',
			chosenDaypart: 1,
			chosenStations: ['g_301014','g_301018','g_201021','g_701014'],
			chosenStatistic: 'shareToAll',
			chosenFirstSurvey: "",
			chosenLastSurvey: "",

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
			mysurveylist: [],
			mycatnames: [],

			showStationSelectOverlay: false,

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
			],
			demoGroupsYoYSMBAP: [
				{key: 'People', dids: [48,65,66,78,67,68,255,535,110,98,9225]},
				{key: 'Males', dids: [49]},
				{key: 'Females', dids: [50]},
			],
			daypartIds: [1,2,3,4,5,6,7,8,18,19,20,23,24,26,28,29,30,38,40],
			nRecentSurveys: 20,

		}
	},
	computed: {
		endYear() {
			return new Date().getFullYear()
		},
		yearOptions() {
			let y = []
			for(let i=this.endYear; i>=this.startYear; i--) {
				y.push(i)
			}
			return y
		},
		demoGroupsShortcut() {
			return this.store.demoGroupsAce
		},
		markets() {
			return this.store.marketObs.filter(item => item.isGfk === true)
		},
		allDemoIds() {
			let d = []
			for(let did of this.demoGroupsShortcut) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demos() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.demoGroupsShortcut.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.store.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		dayparts() {
			if(this.store.daypartObs) {
				return this.store.daypartObs.filter(item => this.daypartIds.includes(item.id))
			}
			return []
		},
		chartStationObs() {
			if(this.store.stationObs.length > 0) {
				return this.store.stationObs.filter(item => this.chosenStations.includes(item.id))
			}
			return []
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarket)
		},
		surveyList() {
			if(this.activeMarketOb) {
				let sobs = this.store.surveyObs.filter(item => item.mktid === this.activeMarketOb.id)
				let newsobs = []
				for(let s of sobs) {
					if(s.relyear >= this.chosenStartYear && s.relyear <= this.chosenEndYear) {
						newsobs.push(s)
					}
				}
				return newsobs
			}
			return []
		},
		surveyListIds() {
			let ss = []
			for(let su of this.surveyList) {
				ss.push(su.id.replace("ME","GF"))
			}
			return ss
		},
		filteredSurveyIds() {
			if(this.surveyListIds.length === 0) return []
			let s = []
			for(let myid of this.surveyListIds) {
				let syr = parseInt(myid.substr(2,4))
				if(syr >= this.chosenStartYear && syr <= this.chosenEndYear) {
					s.push(myid)
				}
			}
			return s
		},
		chartDataFiltered() {
			if(!this.store.returnedData || this.store.returnedData.length === 0) return []
			return this.store.returnedData.filter(item => this.filteredSurveyIds.includes(item.survcode))
		},
		surveyListIdOldToNew() {
			let sarr = JSON.parse(JSON.stringify(this.filteredSurveyIds)).sort().reverse()
			let outer = []
			for(let s of sarr) {
				outer.push({id: s, nicename: s})
			}
			return outer
		},
		tableData() {
			let data = {}
			for(let item of this.chartDataFiltered) {
				let survcode = item.survcode
				if(!data[survcode]) {
					data[survcode] = {}
				}
				for(let d of item.data) {
					let stid = d.stid
					data[survcode][stid] = d.val
				}
			}
			return data
		},
	},
	methods: {
		getDataPoint(survcode, stid) {
			if(this.tableData[survcode] && this.tableData[survcode][stid]) return this.tableData[survcode][stid]
			return null
		},
		tblCellNumDisplay(rawval) {
			if(this.chosenStatistic === 'tslHrsDecimal') return this.numdisplay(rawval, 2)
			else if(this.chosenStatistic === 'Share') return this.numdisplay(rawval, 1)
			else if(['avgAud','cume'].includes(this.chosenStatistic)) {
				return rawval/1000
			}
			return rawval
		},
		getChartData() {
			if(this.getChartsBtnWorking === false) {
				this.getChartsBtnWorking = true;
				let self = this
				let sets = []
				let type = 'standard'
				let configs = [
					{mktid: 110, stns: ['g_201021'], ms: 'SY'},
					{mktid: 111, stns: ['g_301014','g_301018'], ms: 'ME'},
					{mktid: 112, stns: ['g_701014'], ms: 'BR'},
				]
				for(let c of configs) {
					let sar = JSON.parse(JSON.stringify(this.surveyListIds))
					let newSurveyListIds = []
					for(let s of sar) {
						let yr = parseInt(s.substr(2,4))
						if([110,112].includes(c.mktid) && yr < 2022) continue
						newSurveyListIds.push(s.replace("GF", c.ms))
					}
					sets.push({
						type: type,
						dayparts: [this.chosenDaypart],
						demos: [this.chosenDemographic],
						statistics: [this.chosenStatistic],
						surveylist: newSurveyListIds,
						stationIds: c.stns,
						market: c.mktid,
						audioTypeIds: [0],
						cadence: this.cadence,
					})
				}

				this.doGetCacheDataAudology(sets).then(function(ret) {
					self.getChartsBtnWorking = false
					if(ret.error && ret.error === 1) console.log(ret)
					//recode returned data to match the survey codes together
					let newdata = []
					let newdataBySurv = {}
					for(let r of self.store.returnedData) {
						let mysurvcode = r.survcode
						let newsurvcode = mysurvcode
						if(mysurvcode.indexOf("SY") === 0) newsurvcode = mysurvcode.replace("SY","GF")
						else if(mysurvcode.indexOf("ME") === 0) newsurvcode = mysurvcode.replace("ME","GF")
						else if(mysurvcode.indexOf("BR") === 0) newsurvcode = mysurvcode.replace("BR","GF")
						if(!newdataBySurv[newsurvcode]) {
							newdataBySurv[newsurvcode] = r
							newdataBySurv[newsurvcode].survcode = newsurvcode
						}
						else {
							for(let d of r.data) {
								newdataBySurv[newsurvcode].data.push(d)
							}
						}
					}
					for(let survcode in newdataBySurv) {
						newdata.push(newdataBySurv[survcode])
					}
					self.store.returnedData = newdata
				})
			}
		},
		updateColours() {
			let outcols = [];
			for(let i=0; i<this.store.returnedData.length; i++) {
				let gfkstnob = this.store.returnedData[i].stationob;
				let stnob = this.store.stationObs.find(item => item.id === gfkstnob.stid);
				if(stnob) {
					outcols.push(this.getChartColorForStation(stnob, outcols));
				}
				else { //use the GFK station lookup
					outcols.push(this.getChartColorForStation(gfkstnob, outcols));
				}
			}
			this.chartoptions.colors = outcols;
			this.chartoptions = {...this.chartoptions, ...{
				colors: outcols
			}};
		},
		selectRecentSurveys(n) {
			this.nRecentSurveys = n
		},
		selectAllSurveys() {
			this.nRecentSurveys = 999
		},
	},
	watch: {
		chosenStatistic() {
			this.getChartData();
		},
		chosenDaypart() {
			this.getChartData();
		},
		chosenDemographic() {
			this.getChartData();
		},
		cadence() {
			this.getChartData();
		},
		chosenDemoGroup() {
			this.chosenDemographic = this.demoDisplay[0].id
		},
		'store.notYetFirstLoadDone': {
			handler() {
				if(this.store.notYetFirstLoadDone === false) this.getChartData()
			}
		},
	},
	props: {

	},
	mounted() {
		this.chosenEndYear = this.endYear
		if(this.store.notYetFirstLoadDone === false) {
			this.getChartData()
		}
	},
	created() {
		document.title = "Ace - Ratings Charts"
	},
}
</script>

<style scoped>
.page-title {
	width: 150px;
	flex-shrink: 0;
}
.filter-holder .select-styled {
	max-width: 200px;
}

</style>

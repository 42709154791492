<template>
	<div id="appportal" :class="'skin-'+store.skin">

		<div class="main-container">
			<div v-if="store.notYetFirstLoadDone === true" class="inner">
				<HeaderNav></HeaderNav>
				<div style="padding: 30px 0;">
					Loading...please wait...
				</div>
			</div>
			<div v-else-if="!store.notYetFirstLoadDone && !store.dashId" class="inner">
				<HeaderNav></HeaderNav>
				<div style="padding: 30px 0;">
					Invalid dashboard
				</div>
			</div>
			<div v-else-if="store.notYetFirstLoadDone && !store.dashId" class="inner">
				<HeaderNav></HeaderNav>
				<div style="padding: 30px 0;">
					{{errorMsg}}
				</div>
			</div>
			<div v-else class="">
				<DashNova1 v-if="store.dashOb && store.dashOb.id === 1"></DashNova1>
				<DashSCA2 v-if="store.dashOb && store.dashOb.id === 2"></DashSCA2>
				<DashARN4 v-if="store.dashOb && store.dashOb.id === 4"></DashARN4>
				<DashCRA5 v-if="store.dashOb && store.dashOb.id === 5"></DashCRA5>
				<DashAce6 v-if="store.dashOb && store.dashOb.id === 6"></DashAce6>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import DashNova1 from "@/components/views/DashNova1.vue";
import DashSCA2 from "@/components/views/DashSCA2.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import DashARN4 from "@/components/views/DashARN4.vue";
import DashCRA5 from "@/components/views/DashCRA5.vue";
import DashAce6 from "@/components/views/DashAce6.vue";
export default {
	name: 'App',
	components: {
		DashAce6,
		DashCRA5,
		DashARN4,
		HeaderNav,
		DashSCA2,
		DashNova1,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			pathname: null,
			errorMsg: null,
		}
	},
	computed: {

	},
	methods: {
		initialFetch(str) {
			let self = this
			let api = 'https://platform.audology.app/api/dashboards/'
			axios.get(
				api+"get-config?str="+str
			).then(function (response) {
				if(response.data.error) {
					self.errorMsg = response.data.error
					self.store.notYetFirstLoadDone = false
					return
				}
				self.store.dashId = response.data.dash.str
				if(response.data.dash) {
					self.store.dashOb = response.data.dash
					self.store.skin = response.data.dash.skin
					document.title = response.data.dash.name+' Dashboard'
				}
				self.store.configData = response.data.configData
				self.store.stationObs = response.data.stations
				self.store.demoObs = response.data.demos
				if(self.store.surveyObs.length === 0) self.store.surveyObs = response.data.surveys
				if(self.store.marketObs.length === 0) self.store.marketObs = response.data.markets
				self.store.daypartObs = response.data.dayparts
				self.store.qtrHrObs = response.data.qtrHrs
				self.store.notYetFirstLoadDone = false
			}).catch(function (error) {
				self.wasLoadingError = true
				self.store.notYetFirstLoadDone = false
				console.log(error)
			});
		},
	},
	watch: {

	},
	mounted() {
		let p = window.location.pathname
		if(p[p.length-1] === "/") p = p.substring(0, p.length-1)
		if(p[0] === "/") p = p.substring(1, p.length)
		this.pathname = p
		if(p.length === 0) this.store.notYetFirstLoadDone = false
		if(p.length > 0) {
			this.initialFetch(p)
		}
		//check if we have a session token for Audology already
		let token = localStorage.getItem('audologyServerSessionToken')
		if(token) this.store.audologyServerSessionToken = token
		this.store.userEmail = localStorage.getItem('userEmail')
		this.checkSessionForEarly(token, p)

		//check if localstorage key for loggedin exists
		let loggedIn = localStorage.getItem('loggedIn')
		let lastlogintime = parseInt(localStorage.getItem('lastlogintime'))
		let now = Math.floor(Date.now() / 1000)
		let days28ago = now - (3600 * 24 * 28)
		//if last login time is more than 28d ago, then set loggedIn to false
		if(lastlogintime < days28ago) {
			loggedIn = 'false'
			localStorage.setItem('loggedIn', 'false')
			localStorage.setItem('audologyServerSessionToken', null)
		}
		else {
			if (loggedIn === 'true') {
				this.store.userLoggedIn = true
			}
		}
	},
	created() {
		//handle incoming without router
		let queryString = window.location.search
		let urlParams = new URLSearchParams(queryString)
		if(urlParams.has('preview')) {
			let isIframe = urlParams.get('preview')
			if(isIframe === '1' || isIframe === 'true' || isIframe === true) {
				this.store.previewDevMode = true
			}
		}
	},
}
</script>

<style>
#appportal {
	min-height: 100vh;
	background-color: var(--greybg);
}
.main-container {
	margin-top: 70px;
	width: 100%;
}

</style>

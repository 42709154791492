import axios from "axios";
import TableToExcel from "@linways/table-to-excel";

export const globalMixin = {
	methods: {
		numdisplay: function (num, dp = 0) {
			if(!num) return num
			else if(isNaN(num)) return num
			return num.toLocaleString('en-US', {minimumFractionDigits: dp, maximumFractionDigits: dp});
		},
		numdisplayNdp: function (num, numberOfDecplc, showZeroes = true) {
			if(num == 0 && showZeroes === true) return 0
			else if(num == 0 && showZeroes === false) return null
			if(!num) return null
			if(this.store.showCommas) return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
			else return num.toFixed(numberOfDecplc);
		},
		numdisplayNoZeroes(num) {
			if(num === 0) return '';
			else return this.numdisplay(num);
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			let a = new Date(unix_timestamp * 1000);
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			let year = a.getFullYear();
			let month = months[a.getMonth()];
			let date = a.getDate();
			let timestr = "";
			if (includeTime === true) {
				let hour = a.getHours();
				let min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// let sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		boolToInt: function(boolin) {
			if(boolin === true) return 1;
			return 0;
		},
		trueToYes: function(str) {
			if(str === true) return "Yes";
			return '';
		},
		survDateToNice(yyyymm) {
			if(yyyymm === 0) return '';
			let str = yyyymm.toString();
			let y = str.substring(0,4);
			let m = str.substring(4,6);
			return y+"-"+m;
		},
		survGfkNiceName(yYYYYsS) {
			if(yYYYYsS.indexOf('s' !== false)) {
				let arr = yYYYYsS.split('s');
				return 's'+arr[1]+' '+arr[0].replace('y','');
			}
			return yYYYYsS;
		},
		competitiveNice(num) {
			if(num === 0) return '';
			else return 'Yes';
		},
		convertNetworkAlign(str) {
			if(str === 'arn_pg') return 'Pure Gold';
			else if(str === 'arn_k') return 'KIIS';
			return str;
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		convertToUrlName(str) {
			str = str.replaceAll("/","");
			str = str.replaceAll("'","");
			str = str.replaceAll(' ','-').toLowerCase();
			str = str.replaceAll('--','-').toLowerCase();
			return str;
		},
		strContains(string, substringArr) {
			let strlower = string.toLowerCase()
			for(let substring of substringArr) {
				substring = substring.toLowerCase()
				if(strlower.indexOf(substring) !== -1) {
					return true
				}
			}
			return false
		},
		getChartColorForStation(stnob, defaultColorCount) {
			let col = ''
			if(stnob.name && (stnob.name.toLowerCase().includes('edge') || stnob.name.toLowerCase().includes('cada'))) {
				let l = this.store.netcolors.find(item => item.key === 'cada');
				if (l) col = l.col;
			}
			let s = stnob.repsubnet
			if(s === 'arn_k' && this.store.skin === 'sca') s = 'arn_k_scaoverride'
			if(s === 'sca_m' && this.store.skin === 'sca') s = 'sca_m_scaoverride'
			if(s === 'sca_h' && this.store.skin === 'sca') s = 'sca_h_scaoverride'
			if(s === 'abc_j' && this.store.skin === 'sca') s = 'abc_j_scaoverride'
			let l = this.store.netcolors.find(item => item.key === s)
			if(l) col = l.col
			if(col === '') {
				let s = stnob.subnetown
				if(s === 'arn_k' && this.store.skin === 'sca') s = 'arn_k_scaoverride'
				if(s === 'sca_m' && this.store.skin === 'sca') s = 'sca_m_scaoverride'
				if(s === 'sca_h' && this.store.skin === 'sca') s = 'sca_h_scaoverride'
				if(s === 'abc_j' && this.store.skin === 'sca') s = 'abc_j_scaoverride'
				l = this.store.netcolors.find(item => item.key === s)
				if(l) col = l.col
			}
			if(col === '') {
				let s = stnob.netgroupown
				if(s === 'ace' && stnob.name.toLowerCase().indexOf('magic') !== -1) s = 'magic'
				if(this.store.skin === 'ace' && this.store.innerPageView === 'Ace Stations') {
					if(s === 'ace' && stnob.name.toLowerCase().indexOf('4bh') !== -1) s = '4bh'
					if(s === 'ace' && stnob.name.toLowerCase().indexOf('2ue') !== -1) s = '2ue'
				}
				if(s === 'arn_k' && this.store.skin === 'sca') s = 'arn_k_scaoverride'
				if(s === 'sca_m' && this.store.skin === 'sca') s = 'sca_m_scaoverride'
				if(s === 'sca_h' && this.store.skin === 'sca') s = 'sca_h_scaoverride'
				if(s === 'abc_j' && this.store.skin === 'sca') s = 'abc_j_scaoverride'
				l = this.store.netcolors.find(item => item.key === s) //catches nine etc
				if(l) col = l.col
			}
			if(col === '') {
				let str = stnob.name.toLowerCase()
				if(str.indexOf('kiis') !== -1 && this.store.skin === 'sca') l = this.store.netcolors.find(item => item.key === 'arn_k_scaoverride')
				else if(str.indexOf('hit network') !== -1 && this.store.skin === 'sca') l = this.store.netcolors.find(item => item.key === 'sca_h_scaoverride')
				else if(str.indexOf('kiis') !== -1) l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(str.indexOf('gold') !== -1) l = this.store.netcolors.find(item => item.key === 'arn_p')
				else if(str.indexOf('smooth') !== -1) l = this.store.netcolors.find(item => item.key === 'nova_s')
				else if(str.indexOf('nova') !== -1) l = this.store.netcolors.find(item => item.key === 'nova_n')
				else if(this.strContains(str, ['nine','3aw','4bc','6pr','2gb']) === true) l = this.store.netcolors.find(item => item.key === 'nine')
				else if(this.strContains(str, ['hit','fox','2day','b105','safm']) === true) l = this.store.netcolors.find(item => item.key === 'sca_h')
				else if(this.strContains(str, ['mmm','triple m']) === true && this.store.skin === 'sca') l = this.store.netcolors.find(item => item.key === 'sca_m_scaoverride')
				else if(this.strContains(str, ['mmm','triple m']) === true) l = this.store.netcolors.find(item => item.key === 'sca_m')
				else if(this.strContains(str, ['jjj','triple j']) === true && this.store.skin === 'sca') l = this.store.netcolors.find(item => item.key === 'abc_j_scaoverride')
				else if(this.strContains(str, ['jjj','triple j']) === true) l = this.store.netcolors.find(item => item.key === 'abc_j')
				else if(this.strContains(str, ['5aa','fiveaa']) === true) l = this.store.netcolors.find(item => item.key === 'nova_aa')
				else if(this.strContains(str, ['2ca','ace']) === true) l = this.store.netcolors.find(item => item.key === 'ace')
				else if(this.strContains(str, ['2cc']) === true) l = this.store.netcolors.find(item => item.key === 'nine')
				else if(this.strContains(str, ['2hd']) === true) l = this.store.netcolors.find(item => item.key === 'arn_p')
				else if(this.strContains(str, ['newfm']) === true && this.store.skin === 'sca') l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(this.strContains(str, ['newfm']) === true) l = this.store.netcolors.find(item => item.key === 'arn_k_scaoverride')
				else if(this.strContains(str, ['arn']) === true) l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(this.strContains(str, ['sca']) === true) l = this.store.netcolors.find(item => item.key === 'sca_h')
				else if(this.store.skin === 'ace') {
					if(this.strContains(str, '4bh') === true) l = this.store.netcolors.find(item => item.key === '4bh')
					if(this.strContains(str, '2ue') === true) l = this.store.netcolors.find(item => item.key === '2ue')
					if(this.strContains(str, '3mp') === true) l = this.store.netcolors.find(item => item.key === '3mp')
					if(this.strContains(str, 'magic') === true) l = this.store.netcolors.find(item => item.key === 'magic')
				}
				if(l) col = l.col
			}
			if(col === '') {
				col = this.store.defaultColors[defaultColorCount]
				defaultColorCount++;
				if (defaultColorCount >= this.store.defaultColors.length) {
					defaultColorCount = 0
				}
			}
			return [col, defaultColorCount]
		},
		getChartColorByNetworkString(netnamekey, existColorArray) {
			let l = this.store.netcolors.find(item => item.key === netnamekey);
			if (l) return l.col;
			let defaultColorCount = 0;
			for(let c=0; c<existColorArray.length; c++) {
				let mycolor = existColorArray[c];
				if(this.store.defaultColors.includes(mycolor)) defaultColorCount++;
			}
			return this.store.defaultColors[defaultColorCount];
		},
		stripTags(str) {
			return str.replaceAll('<br/>',' ');
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		dateNow() {
			let d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		},
		getCellData(stid, survid, baseData, chosenStatistic, chosenDaypart, chosenDemo, chosenRadioType = 0, divideBy1000 = true, chosenPOL = 0) {
			let survitem = null
			if(chosenPOL === 0) {
				survitem = baseData.find(
					item => item.survcode === survid
						&& item.statistic === chosenStatistic
						&& item.daypartId === chosenDaypart
						&& item.demoId === chosenDemo
						&& item.audioType === chosenRadioType
				)
			}
			else {
				survitem = baseData.find(
					item => item.survcode === survid
						&& item.statistic === chosenStatistic
						&& item.daypartId === chosenDaypart
						&& item.demoId === chosenDemo
						&& item.audioType === chosenRadioType
						&& item.pol === chosenPOL
				)
			}
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) {
					if(divideBy1000 && (chosenStatistic === 'cume' || chosenStatistic === 'avgAud')) return valitem.val / 1000
					return valitem.val
				}
			}
			return null
		},
		doGetCacheDataAudology(requestSets) {
			let self = this
			let postjson = JSON.stringify({
				apicreds: this.store.dashOb.audologyAPICreds,
				userEmail: this.store.userEmail,
				audologyServerSessionToken: this.store.audologyServerSessionToken,
				requestSets: requestSets,
			})
			let api = this.store.audologyAPIUrl
			return axios.post(api+"get-chart-data", postjson).then(function (response) {
				self.store.returnedData = response.data.returnedData
				self.store.returnedQtrData = response.data.qtrdata
				self.store.returnedPOLData = response.data.poldata
				return response
			}).catch(function (error) {
				console.log(error)
			});
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getMarketName(mktid) {
			let mkt = this.store.marketObs.find(item => item.id === mktid)
			if(mkt) return mkt.name
			return ''
		},
		getDaypartName(dpid) {
			let dp = this.store.daypartObs.find(item => item.id === dpid)
			if(dp) return dp.name
			return ''
		},
		setLocalstorageLogin() {
			window.localStorage.setItem('loggedIn', 'true')
			window.localStorage.setItem('lastlogintime', Math.floor(Date.now() / 1000).toString())
		},
		handleLoginReturn(ret) {
			if(ret.loginok) { //if this comes back we have logged in successfully
				this.store.userLoggedIn = true
				this.setLocalstorageLogin()
			}
			if(ret.loginInfo) {
				if(ret.loginInfo.emailAddr) {
					this.store.userEmail = ret.loginInfo.emailAddr
					window.localStorage.setItem('userEmail', ret.loginInfo.emailAddr)
				}
			}
			if(ret.token) {
				this.store.audologyServerSessionToken = ret.token
				window.localStorage.setItem('audologyServerSessionToken', ret.token)
				this.checkSessionForEarly(ret.token, this.store.dashId)
			}
			if(ret.loginInfo) return ret.loginInfo
			return {
				dashIsSet: null,
				emailAddr: null,
				dashId: null,
			}
		},
		checkSessionForEarly(token, dashboardGetIdStr) {
			let api = 'https://platform.audology.app/post/dashboards/dash-sesscheck.php'
			let postjson = JSON.stringify({
				token: token,
				emailAddress: this.store.userEmail,
				dashboardId: dashboardGetIdStr, //string value ID
			})
			let self = this
			axios.post(api, postjson).then(function (response) {
				let ret = response.data
				if(ret.token) {
					self.store.audologyServerSessionToken = ret.token
					window.localStorage.setItem('audologyServerSessionToken', ret.token)
					if(ret.isEarlyUser === true) {
						self.store.userIsEarlyReleaseAndHasToken = true
					}
				}
				else {
					if(ret.isEarlyUser === true) { //no token but is early user, do login again
						window.localStorage.setItem('loggedIn', 'false')
						self.store.userLoggedIn = false
					}
				}
				if(ret.surveys) self.store.surveyObs = ret.surveys
				if(ret.markets) self.store.marketObs = ret.markets
			}).catch(function (error) {
				console.log(error)
			});
		},
		forceLogout() {
			this.store.userLoggedIn = false
			this.store.userEmail = null
			this.store.audologyServerSessionToken = null
			window.localStorage.removeItem('loggedIn')
			window.localStorage.removeItem('userEmail')
			window.localStorage.removeItem('audologyServerSessionToken')
		},
	},

}
<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsARN" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<ChartHolderDash charttitle="Mon-Sun 5.30am-12mn">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="baseData.length > 0"
						:basedata="baseData" :chosendaypart="1" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 5.30am-9am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="3" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 6am-9am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="17" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 6am-10am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="986" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 9am-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="20" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 9am-12nn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="4" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 12pm-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="5" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 3pm-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="24" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 4pm-6pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="23" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 4pm-7pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="6" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 6pm-7pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="38" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 7pm-8pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="40" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 7pm-10pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="39" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 8pm-10pmn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="121" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 10pm-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="61" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 5.30am-7pm (BMAD)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="19" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sat-Sun 12am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="8" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sat 12am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="62" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sun 12am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="63" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="chosenMarketId === 14" class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 5am-9am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="25" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="chosenMarketId === 14" class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 12nn-3pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="26" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 3pm-7pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="27" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="marketid < 300 && chosenStatistic !== 'tslHrsDecimal'" class="chart-rows">
				<ChartHolderDash charttitle="Quarter Hour Breakout">
					<template v-slot:headerRight>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="myQtrHrData" :chartstationlist="chartStationList"
							:chosenstatistic="chosenStatistic" :showmarkers="false"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Quarter Hour Breakout (previous survey)">
					<template v-slot:headerRight>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="prevQtrHrData" :chartstationlist="chartStationList"
							:chosenstatistic="chosenStatistic" :showmarkers="false"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import ApexFreqLineQtrMultiStn from "@/components/ApexFreqLineQtrMultiStn.vue";

export default defineComponent({
	name: "DashARNMarket",
	mixins: [globalMixin],
	components: {
		ApexFreqLineQtrMultiStn,
		HeaderNav,
		ChartLoaderInsert,
		ApexFreqBar,
		ChartHolderDash
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
		marketid: Number,
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'shareToAll', //cume
			chosenDemo: 48,
			chosenDemoGroup: 'People',

			allowedQtrHrDayparts: [92, 90, 91],
			chosenQtrHrDaypart: 92,

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
				{id: 113, name: 'Adelaide'},
				{id: 114, name: 'Perth'},
				{id: 14, name: 'Gold Coast'},
				{id: 900, name: 'SMBAP'},
				{id: 901, name: 'SMB'},
				{id: 902, name: 'SYD/MEL'},
			],

			natCombinedMarketIds: [900,901,902],
		}
	},
	computed: {
		allDemoIds() {
			let d = []
			let mine = this.store.demoGroupsARN
			if(this.natCombinedMarketIds.includes(this.marketid)) mine = this.store.demoGroupsARN_SMBAP
			for(let did of mine) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsARN.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		chosenMarketId() {
			return this.marketid
		},
		chartStationIds() {
			if(this.marketid === 110) return ['g_201009','g_2217','g_201056','g_2207','g_2222','g_2209','g_2202']
			else if(this.marketid === 111) return ['g_301056','g_3217','g_301057','g_3207','g_3223','g_3208','g_3202']
			else if(this.marketid === 112) return ['g_7203','g_7214','g_701056','g_7206','g_701014','g_7201']
			else if(this.marketid === 113) return ['g_8203','g_8213','g_801056','g_8204','g_8214','g_8201']
			else if(this.marketid === 114) return ['g_8905','g_8914','g_8902','g_901012','g_8903','g_8901']
			else if(this.marketid === 14) return ['g_4817','g_427001','g_427036']
			else if(this.natCombinedMarketIds.includes(this.marketid)) {
				return [
					'c_2030', //kiis excl dab
					'c_2117', //nova fm excl dab
					'c_2034', //hit excl dab
					'c_2032', //pure gold excl dab
					'c_2041', //smooth excl dab
					'c_2035', //triple M excl dab
				]
			}
			return []
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		activeMarketOb() {
			let mymktid = this.chosenMarketId
			if(this.natCombinedMarketIds.includes(this.chosenMarketId)) mymktid = 900
			return this.store.marketObs.find(item => item.id === mymktid)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationList() {
			if(this.store.stationObs) {
				let stns = []
				for(let sid of this.chartStationIds) {
					let stn = this.store.stationObs.find(item => item.id === sid)
					if(stn) stns.push(stn)
				}
				return stns
			}
			return []
		},
		allowedQtrDaypartObs() {
			if(this.store.daypartObs.length === 0) return []
			return this.store.daypartObs.filter(item => this.allowedQtrHrDayparts.includes(item.id))
		},
		baseQtrData() {
			if(!this.store.returnedQtrData) return []
			return this.store.returnedQtrData.filter(item => item.marketId === this.chosenMarketId)
		},
		myQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[0] && item.audioType === 0)
		},
		prevQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[1] && item.audioType === 0)
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			let dayparts = [1,3,20,4,5,24,23,6,38,39,61,8,62,63,19,25,26,27,986,17,40,121]
			let type = 'standard'
			if(this.natCombinedMarketIds.includes(this.marketid)) type = 'smbap'
			sets.push({
				type: type,
				dayparts: dayparts,
				demos: [this.chosenDemo],
				statistics: [this.chosenStatistic],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: this.chosenMarketId,
				audioTypeIds: [0],
			})
			if(!this.natCombinedMarketIds.includes(this.marketid)) { //individual market only
				sets.push({
					type: 'qtrhrs',
					dayparts: this.allowedQtrHrDayparts,
					demos: [this.chosenDemo],
					statistics: ['shareToAll','cume','avgAud'],
					surveylist: this.surveyListIds,
					stationIds: this.chartStationIds,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
			}
			this.doGetCacheDataAudology(sets)
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>